import './App.css';
//import { Outlet, Link } from 'react-router-dom';

function App() {
  return (
    <div style={{fontFamily: "Montserrat", fontWeight: 500}}>
      <h1>Nick Yuan</h1>
      <h2>Technical Leader</h2>


      <a href="/Nicholas_Yuan_resume.pdf" download={true}>
        <i className="fa-solid fa-file-pdf" />
        Resume
      </a>

      <br/>

      <a href="https://github.com/Nick-Yawn" target="_blank" rel="noreferrer">
        <i className="fa-brands fa-github" />
        GitHub
      </a>

      <br/>

      <a href="https://www.linkedin.com/in/nick-yawn/" target="_blank" rel="noreferrer">
        <i className="fa-brands fa-linkedin" />
        LinkedIn
      </a>

      <br/>

      <a href="https://medium.com/@Nick_Yawn/hosting-your-react-app-on-s3-with-https-on-a-custom-domain-updated-june-2022-6fc71115a83c" target="_blank" rel="noreferrer">
        <i className="fa-brands fa-medium" />
        Latest Medium Article
      </a>

      <br/>

      <a href="https://youtu.be/BvsL7YYhUvc" target="_blank" rel="noreferrer">
        <i className="fa-brands fa-youtube" />
          Latest Youtube Video
      </a>

      <p style={{paddingLeft: 10}}>
        Updated August 2024
      </p>

    </div>
  );
}

export default App;
